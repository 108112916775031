.dashboard_topHeader {
  display: none;
}

@media (max-width: 1120px) {
  .dashboard_sideHeader {
    display: none;
  }
  .dashboard_topHeader {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    z-index: 5;
  }
}




