.upload-container {
  width: 100%;
  border: 1px solid grey;
}

.plyr {
  width: 100%;
}

.plycustom-container {
  box-shadow: 5px 4px 13px #d0d0d0, -5px -5px 13px #f0f0f0;
  background-color: #fff;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
}

.flex .plyr .plyr__controls {
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  /* border-radius: 14px; */
}

.plyr__controls__item .plyr__menu__container {
  top: 50px;
}
.plyr__menu__container #plyr-settings-9455-home div {
  background-color: #fff !important;
}

.plyr__menu__container #plyr-settings-9455-speed div {
  background-color: #fff !important;
}
.plyr__controls .plyr__controls__item[data-plyr="rewind"],
.plyr__controls .plyr__controls__item[data-plyr="fast-forward"] {
  display: none;
}

