@import url('https://fonts.googleapis.com/css2?family=Calligraffitti&family=Concert+One&family=Indie+Flower&family=Inter:wght@100..900&family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Martian+Mono:wght@100..800&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Source+Serif+4:ital,opsz,wght@0,8..60,200..900;1,8..60,200..900&display=swap');

.roboto-medium {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
}
p,
li {
  font-size: 20px;
  color: #242424;
  line-height: 32px;
}
p:has(> img) {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ql-size-large {
  font-size: 24px;
}
.ql-size-huge {
  font-size: 28px;
}
@media (max-width: 680px) {
  .ql-size-huge {
    font-size: 24px;
  }
  p,
  li {
    font-size: 16px;
  }
  .ql-size-large {
    font-size: 20px;
  }
}
