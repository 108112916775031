.loading-state {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100svh;
  background-color: rgba(255, 255, 255, 0.3);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 10px solid #ddd;
  border-top-color: rgb(0, 17, 255);
  animation: loading 1s linear infinite;
}
.loading-state_abs {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading_abs {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 10px solid #ddd;
  border-top-color: rgb(0, 17, 255);
  animation: loading 1s linear infinite;
}
@keyframes loading {
  to {
    transform: rotate(360deg);
  }
}

/* HTML: <div class="loader"></div> */
.loader {
  color: #000;
  width: 4px;
  aspect-ratio: 1;
  border-radius: 50%;
  box-shadow: 19px 0 0 7px, 38px 0 0 3px, 57px 0 0 0;
  transform: translateX(-38px);
  animation: l21 0.5s infinite alternate linear;
}

@keyframes l21 {
  50% {
    box-shadow: 19px 0 0 3px, 38px 0 0 7px, 57px 0 0 3px;
  }
  100% {
    box-shadow: 19px 0 0 0, 38px 0 0 3px, 57px 0 0 7px;
  }
}
