.aibot {
  position: fixed;
  right: 0;
  top: 50%;
  border-start-end-radius: 0;
  border-end-start-radius: 10px;
  background-color: #e9e9f7;
  transition: width 0.5s ease, max-width 0.5s ease, height 0.5s ease,
    top 0.5s ease;
}
.open-aibot {
  width: 100%;
  max-width: 400px;
  height: 100%;
  top: 0;
  /* justify-content: center; */
  flex-direction: column;
  border-radius: 0px;
}
.open-bot {
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes openAnim {
  from {
    width: auto;
    height: auto;
  }
  to {
    width: 100%;
    max-width: 400px;
  }
}

.chat::-webkit-scrollbar {
  display: none;
}

@media (max-width: 700px) {
  .open-aibot {
    width: 100%;
    max-width: 600px;
    height: 100%;
    top: 0;
    /* justify-content: center; */
    flex-direction: column;
    border-radius: 0px;
  }
}
