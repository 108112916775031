.demo-test {
  background-color: #fff;
  width: 90%;
  margin: 0 auto;
  padding-bottom: 20px;
}

.container {
  width: 70%;
}

.stapper {
  width: 100%;
  padding-top: 20px;
}

.stapper ul li {
  width: 50px;
  height: 50px;
  background-color: #3498db;
  text-align: center;
  line-height: 50px;
  color: white;
  border-radius: 4px 4px 0 0;
}

.stepper-line {
  width: 100%;
  height: 10px;
  border-radius: 0 0 4px 4px;
  background-color: #f2f3f4;
}
.stepper-line div {
  display: block;
  height: 100%;
  width: 100%;
  background: linear-gradient(
    to top,
    rgb(82, 232, 160) 0%,
    rgb(26, 204, 229) 100%
  );
  border-radius: 0 0 4px 4px;
  transition: 1s;
}

/* card */

.card {
  width: 100%;
}

.card-subheader {
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-context {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
}

.card-footer button {
  flex-grow: 1;
}

@media (max-width: 1350px) {
  .demo-test {
    width: 100%;
  }
  .container {
    width: 90%;
  }

  .stapper {
    padding-top: 10px;
  }

  .card-waiting {
    width: 100%;
  }
  .card-waiting svg {
    height: 40px;
    width: 40px;
  }
}

@media (max-width: 570px) {
  .stapper {
    padding-top: 5px;
  }
  .stapper ul li {
    width: 35px;
    height: 35px;
    font-size: 11px;
    line-height: 35px;
  }
  .stepper-line {
    width: 100%;
    height: 8px;
  }

  .card-waiting {
    padding: 10px 5px;
  }

  .card-footer {
    flex-direction: column;
  }
}

@media (max-width: 460px) {
  .card-waiting {
    padding: 5px 2px;
  }
  .card-waiting svg {
    height: 20px;
    width: 20px;
  }
  .card-waiting h2 {
    font-size: 10px;
  }
}
