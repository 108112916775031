.visualization-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #3498db; /* Updated circle color */
  animation: circleAnimation 0.5s infinite alternate;
  box-shadow: 0 0 10px rgba(52, 152, 219, 0.7); /* Added box-shadow */
}

.circle-not {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #3498db; /* Updated circle color */
  box-shadow: 0 0 10px rgba(52, 152, 219, 0.7); /* Added box-shadow */
}

#circle1 {
  animation-delay: 0.1s;
}

#circle2 {
  animation-delay: 0.2s;
}

#circle3 {
  animation-delay: 0.3s;
}

@keyframes circleAnimation {
  0% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1.2);
  }
}

@media (max-width: 1350px) {
  .visualization-container {
    width: 100px;
  }
  .circle {
    width: 20px;
    height: 20px;
  }
  .circle-not {
    width: 20px;
    height: 20px;
  }
}
@media (max-width: 770px) {
  .visualization-container {
    width: 70px;
    height: 170px;
  }
  .circle {
    width: 20px;
    height: 20px;
  }
  .circle-not {
    width: 20px;
    height: 20px;
  }
}
