@import url("https://fonts.googleapis.com/css2?family=Calligraffitti&family=Concert+One&family=Indie+Flower&family=Inter:wght@100..900&family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

.hero-wrapper {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}
.hero {
  font-size: 2.375rem;
  line-height: 1.2;
  color: rgb(45, 55, 72);
  font-weight: 700;
}
.hero-context-btns a {
  width: fit-content;
}

@media (max-width: 992px) {
  .hero-wrapper {
    grid-template-columns: 1fr;
  }

  .hero-context h1 {
    margin-top: 60px;
    margin-bottom: 20px;
    text-align: center;
  }
  .hero-context p {
    text-align: center;
  }

  .hero-image img {
    width: 80%;
  }
  .hero-context-btns {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .hero-context-btns a {
    text-align: center;
  }
}

@media (max-width: 480px) {
  /* .hero-context h1 {
    font-size: 40px;
    line-height: 48px; 
    letter-spacing: -0.8px;
  } */

  .hero-wrapper {
    padding-top: 100px;
  }
  .hero-context-btns {
    flex-direction: column;
  }
  .hero-context-btns a {
    width: fit-content;
  }
}

/* why us */
.why_us_card svg {
  color: #52e8a0;
  font-size: 33px;
}
